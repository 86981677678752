
import { Component, Vue, Prop } from 'nuxt-property-decorator'
import AppLazyImage from '~/components/UI/AppLazyImage.vue'

@Component({
  components: {
    AppLazyImage
  }
})
export default class MicroBanner extends Vue {
  @Prop({
    default: 'green'
  })
  theme!: string

  @Prop({
    default: ''
  })
  name!: string

  @Prop({
    default: ''
  })
  description!: string

  @Prop({
    default: ''
  })
  buttonName!: string

  @Prop({
    default: ''
  })
  url!: string

  @Prop({
    default: ''
  })
  image!: string

  get classes() {
    return {
      [`theme-${this.theme}`]: true
    }
  }

  onClickButton() {
    this.$router.push(this.url)
  }
}
